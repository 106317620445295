<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { accessTokenCookie, currentOrganizationCookie, purgeCommonStore } from '~/common/utils/common.utils';

const auth_store = useAuthStore();
const common_store = useCommonStore();

const is_loading = ref(true);

async function initialize() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  accessTokenCookie('set', params.token);

  if (!params.organization)
    return;

  purgeCommonStore();
  currentOrganizationCookie('set', params.organization);
  await common_store.initialize({
    forceUpdate: true,
    toast: false,
  });
  is_loading.value = false;
}

onMounted(initialize);
</script>

<template>
  <div>
    <div v-if="is_loading" class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
      <HawkLoader container_class="m-0 " />
    </div>
    <router-view
      v-else :options="{ can_modify_resource: false, hide_side_bar: true, is_stand_alone: true, hide_dashboard_options: true, show_top_bar: true }"
    />
  </div>
</template>
