<script setup>
import dayjs from 'dayjs';
import { concat } from 'lodash-es';
import { getUserName } from '~/dms/utils/dms.utils';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';

import TransmittalMessageMembersTooltip from '~/dms/components/transmittals/transmittal-detail/transmittal-message-members-tooltip.vue';

const props = defineProps({
  message: {
    type: Object,
    default: () => ({}),
  },
  is_public_page: {
    type: Boolean,
    default: false,
  },
});

const auth_store = useAuthStore();
const common_store = useCommonStore();

const logged_in_user_details = computed(() => auth_store.logged_in_user_details);

const state = reactive({
  is_meta_open: false,
});

const members = computed(() => {
  const { to = [], cc = [] } = props.message;
  return concat(to, cc).map((member) => {
    const internal_user = common_store.get_org_or_internal_user(member);
    return (internal_user || member);
  });
});

const truncated_members = computed(() => members.value?.slice(0, 10));

function openMetaDetails() {
  state.is_meta_open = !state.is_meta_open;
}

const message_owner = computed(() => {
  const uid = props.message.from;
  if (props.is_public_page)
    return props.message.from;
  const user_details = logged_in_user_details.value?.user_id === uid ? logged_in_user_details.value : common_store.get_org_or_internal_user(uid);
  return user_details ? getUserName(user_details) : uid;
});
</script>

<template>
  <div class="flex justify-between w-full">
    <div class="flex relative">
      <HawkMembers v-if="!props.is_public_page" :members="message.from" size="md" />
      <div class="ml-2">
        <div class="text-sm font-semibold">
          <span v-if="props.is_public_page">
            {{ message.from }}
          </span>
          <HawkMembers v-else :members="message.from" size="md" type="label" :has_avatar="false" />
        </div>
        <div v-show="members?.length" class="text-sm text-gray-500 flex items-center">
          <div class="inline-flex">
            <template v-for="member in truncated_members" :key="member?.uid">
              <div class="flex">
                <span v-if="props.is_public_page || !member?.uid">
                  {{ member?.uid || member }}
                </span>
                <HawkMembers v-else :members="member?.uid" :has_avatar="false" type="label" name_classes="!font-normal" />
                <span v-if="member?.uid !== truncated_members.at(-1)?.uid">,&nbsp;</span>
              </div>
            </template>
            <span v-if="members.length > 10">...</span>
          </div>
          <div class="text-gray-700 ml-1 cursor-pointer" @click="openMetaDetails">
            <IconHawkChevronUp
              v-if="state.is_meta_open"
              class="w-[16px] h-[16px]"
            />
            <IconHawkChevronDown
              v-else
              class="w-[16px] h-[16px]"
            />
          </div>
        </div>
        <TransmittalMessageMembersTooltip
          v-if="state.is_meta_open"
          v-click-outside="() => openMetaDetails()"
          :is_public_page="props.is_public_page"
          class="absolute"
          :message="message"
        />
      </div>
    </div>
    <div v-if="message?.timestamp" class="text-gray-500 text-sm">
      {{ $date(message?.timestamp || dayjs(), 'L_DATETIME_LONG_WITH_WEEKDAY') }} ({{ $date_relative(message?.timestamp || dayjs()) }})
    </div>
  </div>
</template>
