<script setup>
// other

// components

// assets
import { useRoute } from 'vue-router';
import HawkInfoIcon from '~/common/components/atoms/hawk-info-icon.vue';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';

const props = defineProps({
  header: {
    type: String,
    default: 'Cancel Request',
  },
  sub_header: {
    type: String,
    default: 'This action cannot be undone.',
  },
  confirm: {
    type: Function,
    default: null,
  },
  is_public_page: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close']);

const route = useRoute();
const form$ = ref(null);
const can_resend = ref(false);
const timer_restart = ref(0);
const transmittal_store = useTransmittalsStore();
const remaining_time = transmittal_store.remaining_time || 60;
const otp = ref('');

async function submitHandler(data, form$) {
  const payload = { ...form$?.data, otp: props.is_public_page ? otp.value : null };
  await props.confirm(payload);
}

function fetch_otp() {
  transmittal_store.set_public_transmittal_otp({ transmittal_uid: route.params.transmittal_uid, query: { payload: route.query.payload } });
}

function resend_otp() {
  transmittal_store.remaining_time = 60;
  timer_restart.value++;
  fetch_otp();
  can_resend.value = false;
}

onMounted(() => {
  if (!transmittal_store.remaining_time && props.is_public_page)
    fetch_otp();
});
</script>

<template>
  <hawk-modal-container content_class="rounded-lg">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 4 },
      }"
      :endpoint="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #header>
            <div
              class="flex items-start p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800"
            >
              <div class="flex items-start">
                <div class="flex items-start mr-4 border-gray-200 text-xl">
                  <component :is="HawkInfoIcon" class="w-12 h-12" />
                </div>
                <div class="flex flex-col justify-start">
                  {{ header }}
                  <span class="font-normal text-sm text-gray-600">
                    {{ sub_header }}
                  </span>
                </div>
              </div>
              <div class="flex font-normal items-center justify-center -m-2">
                <div
                  class="text-gray-600 rounded-md cursor-pointer flex justify-center items-center p-2 ml-3 hover:bg-gray-50"
                  @click="$emit('close')"
                >
                  <IconHawkXClose class="w-6 h-6 text-gray-500 hover:text-gray-900" />
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div v-if="is_public_page" class="text-center grid grid-cols-1 gap-4">
            <div class="text-lg font-semibold">
              {{ $t('Verify the submittal') }}
            </div>
            <div class="text-md text-gray-600">
              {{ $t('Please enter the 8-digit code sent to your email.') }}
            </div>
            <hawk-otp :digit_count="8" @update:otp="otp = $event" />
            <div class="text-md text-gray-600">
              {{ $t('Didn’t receive an email? Resend in') }}
              <hawk-timer :key="timer_restart" :seconds="remaining_time" @timeout="can_resend = true" @remainingTime="transmittal_store.remaining_time = $event" /> {{ $t('secs') }}
              <hawk-button type="link" :disabled="!can_resend" @click="resend_otp">
                {{ $t('Resend') }}
              </hawk-button>
            </div>
          </div>
          <div v-else class="mt-6">
            <WysiwygEditorElement
              name="description"
              :label="$t('Comment')"
              :options="{
                editor_enabled: true,
                menu_enabled: true,
                plugins: ['images', 'tables', 'tags'],
                placeholder_text: $t('Enter info here'),
              }"
            />
            <DmsUploadElement
              class="mt-6"
              name="supporting_documents"
              :label="$t('Supporting documents')"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  button-class="!border !border-transparent"
                  submits
                  :disabled="is_public_page && otp.length !== 8"
                  size="sm"
                  name="submit"
                  :button-label="$t('Transmit')"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
