import { createRouter, createWebHistory } from 'vue-router';
import { onRouteError } from '~/common/middleware/routes.middleware';
import Dashboard from '~/dashboard/pages/dashboard.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [{
    path: '/:asset_id?',
    name: 'home',
    component: Dashboard,
  }],
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.onError(onRouteError);

export default router;
