import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createVfm } from 'vue-final-modal';
import VueTippy from 'vue-tippy';
import vClickOutside from 'click-outside-vue3';
import Toast from 'vue-toastification';
import Vueform from '@vueform/vueform/plugin';

import mitt from 'mitt';
import VueDraggableGrid from '@noction/vue-draggable-grid';

import vueformConfig from '../../../vueform.config';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useI18nStore } from '~/common/stores/i18n.store';
import { services } from '~/common/installers/services';
import { $toast } from '~/common/utils/common.utils';
import { $date, $date_relative } from '~/common/utils/date.util';
import { FixedPosition } from '~/common/utils/fixed-position-directive';
import { truncate, truncateMiddle } from '~/common/filters/truncate.js';
import '@noction/vue-draggable-grid/styles';

import router from '~/apps/dashboard/router.dashboard.js';
import App from '~/apps/dashboard/App.dashboard.vue';

import 'vue-toastification/dist/index.css';
import 'vue-final-modal/style.css';
import 'tippy.js/dist/tippy.css';
import '~/common/styles/main.css';

const emitter = mitt();
const app = createApp(App);
app.directive('fixed-position', FixedPosition);
const vue_final_modal = createVfm();
app.use(vue_final_modal);
app.use(vClickOutside);
app.use(VueDraggableGrid);

app.use(
  VueTippy,
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'auto-end',
      allowHTML: false,
    },
  },
);

app.use(Vueform, vueformConfig);

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$services = markRaw(services);
  store.$router = markRaw(router);
  store.$toast = markRaw($toast);
  store.$date = markRaw($date);
  store.$date_relative = markRaw($date_relative);
});
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(router);

const default_toast_options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  icon: false,
  containerClassName: 'hawk-toast-container',
  closeButton: false,
};
app.use(Toast, default_toast_options);

// install all modules under `installers/`
Object.values(import.meta.glob('./**/installers/*.js')).forEach(i => i.install?.({ app, router }));

const common_store = useCommonStore();
const i18n_store = useI18nStore();
const auth_store = useAuthStore();

// set up global variables
app.config.globalProperties.$t = i18n_store.$t;
app.config.globalProperties.$date = $date;
app.config.globalProperties.$date_relative = $date_relative;
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.current_organization = auth_store.current_organization;
app.config.globalProperties.logged_in_user_details = auth_store.logged_in_user_details;
app.config.globalProperties.active_asset = common_store.active_asset;

// global filters
app.config.globalProperties.$filters = {
  truncate, truncateMiddle,
};

// app-level provides
app.provide('$date', $date);
app.provide('$date_relative', $date_relative);
app.provide('$toast', $toast);
app.provide('$services', services);
app.provide('$t', markRaw(i18n_store.$t));
app.provide('current_organization', auth_store.current_organization);
app.provide('logged_in_user_id', auth_store.logged_in_user_id);

// Mount application
Promise.allSettled([
  i18n_store.load_translations(),
  auth_store.load_split(),
])
  .then(() => {
    app.mount('#app');
  });
